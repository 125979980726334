import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconHouse = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16663 45.8333H45.8333"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.14587 45.8334L6.25004 20.7708C6.25004 19.5 6.85421 18.2917 7.85421 17.5001L22.4375 6.14589C23.9375 4.97922 26.0417 4.97922 27.5625 6.14589L42.1459 17.4792C43.1667 18.2709 43.75 19.4791 43.75 20.7708V45.8334"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M27.0833 35.4167H22.9166C21.1875 35.4167 19.7916 36.8125 19.7916 38.5417V45.8333H30.2083V38.5417C30.2083 36.8125 28.8125 35.4167 27.0833 35.4167Z"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M19.7916 28.6458H15.625C14.4791 28.6458 13.5416 27.7083 13.5416 26.5625V23.4375C13.5416 22.2917 14.4791 21.3542 15.625 21.3542H19.7916C20.9375 21.3542 21.875 22.2917 21.875 23.4375V26.5625C21.875 27.7083 20.9375 28.6458 19.7916 28.6458Z"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M34.375 28.6458H30.2083C29.0625 28.6458 28.125 27.7083 28.125 26.5625V23.4375C28.125 22.2917 29.0625 21.3542 30.2083 21.3542H34.375C35.5208 21.3542 36.4583 22.2917 36.4583 23.4375V26.5625C36.4583 27.7083 35.5208 28.6458 34.375 28.6458Z"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M39.5833 14.5833L39.5208 8.33333H30.3541"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconHouse.defaultProps = {
  className: null,
  rootClassName: null,
};

IconHouse.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconHouse;
