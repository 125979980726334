import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconLoft = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.0416 45.8335H8.49996C6.08329 45.8335 4.10413 43.896 4.10413 41.521V10.6044C4.10413 5.14605 8.16663 2.66688 13.1458 5.10438L22.3958 9.64605C24.3958 10.6252 26.0416 13.2294 26.0416 15.4377V45.8335Z"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.7708 31.3752V39.2502C45.7708 43.7502 43.6875 45.8336 39.1875 45.8336H26.0416V21.7086L27.0208 21.9169L36.3958 24.0211L40.625 24.9586C43.375 25.5627 45.625 26.9794 45.75 30.9794C45.7708 31.1044 45.7708 31.2294 45.7708 31.3752Z"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4583 18.7502H18.6874"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4583 27.0836H18.6874"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3957 24.0211V30.7294C36.3957 33.3127 34.2916 35.4169 31.7083 35.4169C29.1249 35.4169 27.0208 33.3127 27.0208 30.7294V21.9169L36.3957 24.0211Z"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.7499 30.9794C45.6249 33.4377 43.5833 35.4169 41.0833 35.4169C38.4999 35.4169 36.3958 33.3127 36.3958 30.7294V24.0211L40.6249 24.9586C43.3749 25.5627 45.6249 26.9794 45.7499 30.9794Z"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconLoft.defaultProps = {
  className: null,
  rootClassName: null,
};

IconLoft.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLoft;
