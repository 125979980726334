import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconCondo = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.08337 45.8333H47.9167"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.2084 45.8542V36.5625"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.25 22.6875C38.7083 22.6875 36.6666 24.7292 36.6666 27.2709V32C36.6666 34.5416 38.7083 36.5833 41.25 36.5833C43.7917 36.5833 45.8333 34.5416 45.8333 32V27.2709C45.8333 24.7292 43.7917 22.6875 41.25 22.6875Z"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 45.8333V12.5626C4.375 8.37507 6.45844 6.27086 10.6043 6.27086H23.5834C27.7292 6.27086 29.7917 8.37507 29.7917 12.5626V45.8333"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0833 17.1875H22.3958"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0833 25H22.3958"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1875 45.8333V38.0208"
        stroke="#3e5ba6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconCondo.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCondo.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCondo;
